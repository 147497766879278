<template>
  <div class="donut-chart">
    <DoughnutChart v-if="percent < 100" :outerRadius="100" :innerRadius="88" :percentage="progress" />
    <DoughnutChartGradient
      v-else
      :outerRadius="100"
      :innerRadius="88"
      :backgroundColor="'lightgray'"
      :innerBackgroundColor="'white'"
      :percentage="progress"
      :strokeWidth="11"
    />
  </div>
</template>
<script>
import DoughnutChart from '@/components/products/reductions/progress/DoughnutChart.vue'
import DoughnutChartGradient from '@/components/products/reductions/progress/DoughnutChartGradient.vue'

export default {
  components: {
    DoughnutChart,
    DoughnutChartGradient,
  },
  props: {
    progress: {
      type: Number | String,
      default: 0,
    }
  },
  computed: {
    percent() {
      return Math.abs(this.progress);
    }
  }
}
</script>
<style lang="scss" scoped>
.donut-chart {
  width: 136px;
  height: 136px;
}
</style>