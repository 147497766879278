<template>
  <div class="doughnut-chart">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="100%"
      height="100%"
      viewBox="-5 -5 210 210"
    >
      <defs>
        <linearGradient
          id="p1"
          gradientUnits="userSpaceOnUse"
          x1="0"
          y1="0"
          x2="1"
          y2="1"
        >
          <stop offset="0%" :stop-color="progressColor.p1" />
          <stop offset="100%" :stop-color="progressColor.p2" />
        </linearGradient>
        <linearGradient
          id="p2"
          gradientUnits="userSpaceOnUse"
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="0%" :stop-color="progressColor.p2" />
          <stop offset="100%" :stop-color="progressColor.p3" />
        </linearGradient>
        <linearGradient
          id="p3"
          gradientUnits="userSpaceOnUse"
          x1="1"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="0%" :stop-color="progressColor.p3" />
          <stop offset="100%" :stop-color="progressColor.p4" />
        </linearGradient>
        <linearGradient
          id="p4"
          gradientUnits="userSpaceOnUse"
          x1="1"
          y1="1"
          x2="0"
          y2="0"
        >
          <stop offset="0%" :stop-color="progressColor.p4" />
          <stop offset="100%" :stop-color="progressColor.p5" />
        </linearGradient>
        <linearGradient
          id="p5"
          gradientUnits="userSpaceOnUse"
          x1="0"
          y1="1"
          x2="0"
          y2="0"
        >
          <stop offset="0%" :stop-color="progressColor.p5" />
          <stop offset="100%" :stop-color="progressColor.p6" />
        </linearGradient>
        <linearGradient
          id="p6"
          gradientUnits="userSpaceOnUse"
          x1="0"
          y1="1"
          x2="1"
          y2="0"
        >
          <stop offset="0%" :stop-color="progressColor.p6" />
          <stop offset="100%" :stop-color="progressColor.p7" />
        </linearGradient>
      </defs>

      <g fill="transparent" :stroke-width="strokeWidth" transform="translate(100,100)">
        <path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke="url(#p1)"/>
        <path d="M 86.6,-50.2 A 100,100 0 0,1 86.6,50.2" stroke="url(#p2)"/>
        <path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke="url(#p3)"/>
        <path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke="url(#p4)"/>
        <path d="M -86.6,50.2 A 100,100 0 0,1 -86.6,-50.2" stroke="url(#p5)"/>
        <path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke="url(#p6)"/>
      </g>
    </svg>
    <div class="percentage-label">
      <div class="percent-data" :style="getFontSize(formatNumberValue(percentage))">{{ formatNumberValue(percentage) }}</div>
      <div class="percent-unit" :style="formatNumberValue(percentage).length > 12 ? getFontSize(formatNumberValue(percentage)) : ''">%</div>
    </div>
  </div>
</template>

<script>
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
export default {
  props: {
    strokeWidth: {
      type: Number,
      default: 11,
    },
    gradientColors: {
      type: Object,
      default: () => ({
        p1: '#d4a630',
        p2: '#d1a732',
        p3: '#c3ab3a',
        p4: '#b1af45',
        p5: '#9fb44f',
        p6: '#90b858',
        p7: '#89B473',
      }),
    },
    percentage: {
      type: Number | String,
      required: true,
    },
  },
  computed: {
    progressColor() {
      return this.gradientColors
    },
  },
  methods: {
    formatNumberValue(number) {
      if (number === undefined || number === null) return '-';
      return formatNumberByConditions(number, { formatUnit: UNIT_SETTING.PERCENT })
    },
    getFontSize(percent = "") {
      let fontSize = 30
      const lengthPercent = percent?.length || 0
      if(lengthPercent < 7) {
        fontSize = 30
      } else if(lengthPercent >= 7 && lengthPercent < 12) {
        fontSize = 18
      } else if(lengthPercent >= 12  && lengthPercent < 16) {
        fontSize = 12
      } else if(lengthPercent >= 16 && lengthPercent < 19) {
        fontSize = 9
      } else if(lengthPercent >= 19) {
        fontSize = 6
      }
      return `font-size: ${fontSize}px;`
    }
  }
}
</script>

<style lang="scss" scoped>
.doughnut-chart {
  display: inline-block;
  position: relative;
  width: 136px;
  height: 136px;
  margin-top: 4px;
  .percentage-label {
    font-family: 'Source Han Sans';
    color: $goldMid;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: baseline;
    .percent-data {
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0.9px;
    }
    .percent-unit {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.7px;
    }
  }
}
</style>
